import Barba from 'barba.js';
import Isotope from 'isotope-layout';

function allPossibleCases(arr) {
	if (arr.length == 0) {
		return [];
	} else if (arr.length == 1) {
		return arr[0];
	} else {
		var result = [];
		var allCasesOfRest = allPossibleCases(arr.slice(1));  // recur with the rest of array
		for (var i = 0; i < allCasesOfRest.length; i++) {
			for (var j = 0; j < arr[0].length; j++) {
				result.push(arr[0][j] + allCasesOfRest[i]);
			}
		}
		return result;
	}
}


class FilterBar {
	constructor(options) {
		this.grid = options.grid;
		this.tagsBar = options.tagsBar;
		this.filterInputs = options.filterInputs;
		this.filters = [];

		this.isotope = new Isotope(this.grid, {
			itemSelector: options.itemSelector,
			percentPosition: true,
			layoutMode: 'fitRows'
		});

		$(this.grid).addClass('is-showing-items');

		this._applyFilters();

		$(this.filterInputs).on('change', () => {
			this._loadFilters();
		});
	}

	// addFilter(value, label) {
	// 	this.filters.push({ value, label });
	// 	this._applyFilters();
	// 	this._renderTags();
	// }

	removeFilter(value) {
		this.filters = this.filters.filter(f => f.value !== value);
		$(`option[value='${value}']`).prop('selected', false);
		$('select').selectric('refresh');
		this._loadFilters();
		// this._applyFilters();
		// this._renderTags();
	}

	setFilters(filters) {
		this.filters = filters;
		this._applyFilters();
		this._renderTags();
	}

	clearFilters() {
		this.filters = [];
		$('option').prop('selected', false);
		$('select').selectric('refresh');
		this._applyFilters();
		this._renderTags();
	}

	_applyFilters() {
		// Generate filter string
		const filter = this.filters.length ? this.filters.join(', ') : '*';
		this.isotope.arrange({ filter });
	}

	_loadFilters() {
		this._loadFilters2();
		// let filters = [];
		// $(this.filterInputs).each((index, element) => {
		// 	const selectedFilters = $('option:selected', element).map((index, option) => {
		// 		return { value: $(option).val(), label: $(option).text() };
		// 	});
		// 	filters = filters.concat(selectedFilters.toArray());
		// });
		// this.setFilters(filters);
	}

	_loadFilters2() {
		let filters = [];
		$(this.filterInputs).each((index, element) => {
			const values = $('option:selected', element).map((index, option) => {
				return `.${$(option).val()}`;
			});
			if(values.length) {
				filters.push(values.toArray());
			}
		});
		const combinedFilters = allPossibleCases(filters);
		const filterArray = (typeof combinedFilters === 'string') ? [combinedFilters] : combinedFilters;
		this.setFilters(filterArray);
	}

	_renderTags() {
		let filters = [];

		$(this.filterInputs).each((index, element) => {
			const selectedFilters = $('option:selected', element).map((index, option) => {
				return { value: $(option).val(), label: $(option).text() };
			});
			filters = filters.concat(selectedFilters.toArray());
		});

		this.tagsBar.innerHTML = '';

		if(filters.length >= 3) {
			const div = document.createElement('button');
			div.innerHTML = 'Alle filters herstellen';
			div.classList.add('reset-filters-button');
			div.addEventListener('click', () => {
				this.clearFilters();
			});
			this.tagsBar.appendChild(div);
		}

		filters.forEach(({value, label}) => {
			const div = document.createElement('button');
			div.innerHTML = label;
			div.classList.add('remove-filter-button');
			div.addEventListener('click', () => {
				this.removeFilter(value);
			});
			this.tagsBar.appendChild(div);
		});
	}
}


export default Barba.BaseView.extend({
	namespace: 'example',
	filterBar: null,

	onEnter: function() {
		//
	},

	onEnterCompleted: function() {
		this.filterBar = new FilterBar({
			grid: document.querySelector('.cards-container'),
			filterInputs: document.querySelectorAll('.filter'),
			tagsBar: document.querySelector('.filter-active-filters'),
			itemSelector: '.card-holder'
		});
	},

	onLeave: function() {
		$('.calendar').addClass('calendar--loading');
	},

	onLeaveCompleted: function() {
		// The Container has just been removed from the DOM.
	}
});
