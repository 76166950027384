import Barba from 'barba.js';

export default Barba.BaseTransition.extend({
	start: function() {
		this.newContainerLoading.then(() => {
			$(this.oldContainer).find('.calendar').fadeOut();
			$(this.oldContainer).hide();
			$(this.newContainer).find('.calendar').hide();
			$(this.newContainer).css('visibility', 'visible').show();
			$(this.newContainer).find('.calendar').fadeIn();
			this.done();
		});
	}
});
