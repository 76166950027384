import Barba from 'barba.js';

export default Barba.BaseTransition.extend({
	start: function() {
		Promise.all([this.newContainerLoading]).then(() => {
			const oldContainer = $(this.oldContainer);
			const newContainer = $(this.newContainer);
			const toolHolder = oldContainer.find('.tool-holder');

			toolHolder.addClass('explode');
			newContainer.addClass('scale-up').css('visibility', 'visible');

			toolHolder.on('animationend', (event) => {
				if(event.originalEvent.animationName === 'explode-holder') {
					newContainer.removeClass('scale-up');
					oldContainer.hide();
					this.done();
				}
			});
		});
	}
});
