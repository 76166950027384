import Barba from 'barba.js';
import SVGInjector from 'svg-injector-2';
import { ExplodeTransition, ImplodeTransition, FadeTransition, ReplaceTransition } from './transitions';
import { init as initPages } from './pages';

Barba.Pjax.getTransition = () => {
	if($('.calendar-navigation').length > 0 && document.location.pathname !== '/') {
		return ReplaceTransition;
	}

	return FadeTransition;

	// if(document.location.pathname === '/') {
	// 	return ImplodeTransition;
	// } else {
	// 	return ExplodeTransition;
	// }
};

Barba.Dispatcher.on('newPageReady', () => {
	attachModals();
	injectSVG();
	selectSelectric();
});

Barba.Dispatcher.on('initStateChange', (currentStatus) => {
	updateMenu();
});

function updateMenu() {
	if(document.location.pathname !== '/') {
		$('body').addClass('menu-active');
	} else {
		$('body').removeClass('menu-active');
	}
}

function attachModals() {
	$('[data-remodal-id]').remodal();
}

function injectSVG() {
	try {
		new SVGInjector().inject(document.querySelectorAll('img.svg-inject'));
	} catch(e) {
		//
	}
}

function selectSelectric() {
	$('select').selectric({
		onInit: function() {
		$('.filter-container').addClass('show');
		}
	});
}


document.addEventListener('DOMContentLoaded', event => {
	initPages();
	Barba.Pjax.start();
	attachModals();
	updateMenu();
	injectSVG();
	selectSelectric();

	$(document).on('click', '.button-show-print', () => {
		$('.print-select-month').toggleClass('show');
	});

	if(window.location.hash == '#succes') {
		$('#mc_embed_signup').hide();
		$('.newsletter').find('p').html('Bedankt voor je aanmelding!');
	}

	$(document).on('click', '.button-print', (event) => {
		window.open($('.print-select-month select').val());
		event.preventDefault();
		return false;
	});

	$(document).on('change', '#presentation-selector', (event) => {
		Barba.Pjax.goTo($('#presentation-selector').val());
	});
});
