import Barba from 'barba.js';

export default Barba.BaseTransition.extend({
	start: function() {
		Promise.all([this.newContainerLoading]).then(() => {
			const oldContainer = $(this.oldContainer);
			const newContainer = $(this.newContainer);
			const toolHolder = newContainer.find('.tool-holder');

			oldContainer.addClass('scale-down');
			newContainer.css('visibility', 'visible');
			toolHolder.addClass('implode');

			oldContainer.on('animationend', (event) => {
				if(event.originalEvent.animationName === 'scale-up') {
					toolHolder.removeClass('implode');
					oldContainer.hide();
					this.done();
				}
			});
		});
	}
});
