import Barba from 'barba.js';

export default Barba.BaseTransition.extend({
	start: function() {
		this.fadeOut()
			.then(() => this.newContainerLoading)
			.then(this.fadeIn.bind(this));
	},

	fadeOut: function() {
		return $(this.oldContainer).animate({ opacity: 0 }, 240).promise();
	},

	fadeIn: function() {
		$(this.oldContainer).hide();
		$('html, body').scrollTop(0);
		$(this.newContainer).css({
			visibility: 'visible',
			opacity: 0
		}).animate({ opacity: 1 }, 240, () => {
			this.done();
		});
	}
});
