import Barba from 'barba.js';

export default Barba.BaseView.extend({
	namespace: 'calendar',

	onEnter: function() {
    //
	},

	onEnterCompleted: function() {
		// The Transition has just finished.
	},

	onLeave: function() {
		$('.calendar').addClass('calendar--loading');
	},

	onLeaveCompleted: function() {
		// The Container has just been removed from the DOM.
	}
});
